<template>
  <div>
    <div>
      <b-card
        title=""
        action-collapse
        collapsed
      >
        <ValidationObserver
          ref="creatsubmitform"
          slim
        >
          <b-form
            @submit.prevent=""
          >
            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Proof of Delivery"
                  label-for="request-waybill"
                  class="required"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Proof of Delivery"
                    rules="required"
                  >
                    <b-form-input
                      id="requested_proof_of_delivery_count"
                      v-model="requested_proof_of_delivery_count"
                      type="number"
                      placeholder="Request Amount of Proof of Delivery"
                      autocomplete="nope"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Date"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="No of Waybills"
                    label-for="request-waybill"
                  >
                    <b-form-input
                      id="requested_waybill_count"
                      v-model="requested_waybill_count"
                      type="number"
                      placeholder="Request Amount of Waybills"
                      autocomplete="nope"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                cols="6"
                md="3"
              >
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    class="mr-1 custom-button-color"
                    style="display: block; margin-top: 20px;"
                    @click="sendWaybillRequestReport"
                  >
                    Submit
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-card>
    </div>
    <div>
      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <!-- input -->
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <div>
                  <TableButtons
                    :items="rows"
                    :json_fieldz="json_fields"
                    :name="`${downloadReportName} - Page ${page}.xls`"
                    :bulkname="`${downloadReportName}.xls`"
                    :fetch="fetchWaybillRequestListNoPagination"
                    @refresh="fetchWaybillRequestList"
                  />
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- table -->
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              id="waybill-request"
              :fields="fields"
              :items="rows"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              hover
              responsive
              striped
              show-empty
              sticky-header="100vh"
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <!-- Custom template for the empty cells -->
              <template #cell()="data">
                {{ data.value ? data.value : '---' }}
              </template>
              <!-- Proof of Delivery Custom template for the cells -->
              <template #cell(requested_proof_of_delivery_count)="data">
                <div class="custom-width">
                  {{ __formatNumber(data.value) || 0 }}
                </div>
              </template>
              <!-- No Of Waybills Custom template for the cells -->
              <template #cell(requested_waybill_count)="data">
                {{ __formatNumber(data.value) || 0 }}
              </template>
              <!-- Issued Proof of Delivery Custom template for the cells -->
              <template #cell(issued_proof_of_delivery_count)="data">
                <div class="custom-width">
                  {{ __formatNumber(data.value) }}
                </div>
              </template>
              <!-- Issued waybill Custom template for the cells  -->
              <template #cell(issued_waybill_count)="data">
                {{ __formatNumber(data.value) }}
              </template>
              <!-- status badge beautify -->
              <template #cell(req_status)="data">
                <b-badge :variant="data.value === 'confirmed' ? 'light-success' : data.value === 'pending' ? 'light-warning' : 'light-danger'">
                  {{ data.value === 'confirmed' ? 'Confirmed' : (data.value === 'pending' ? 'Pending' : 'Rejected') }}
                </b-badge>
              </template>
              <!-- request_date beautify -->
              <template #cell(request_date)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <!-- confirm_date beautify -->
              <template #cell(confirm_date)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>

            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  class="d-flex align-items-center justify-content-start mb-md-0"
                >
                  <label>Per page</label>
                  <v-select
                    v-model="perPage"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    class="per-page-selector d-inline-block ml-1 width-125"
                  />
                </b-col>
                <!-- Pagination -->
                <b-col
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  cols="12"
                  sm="6"
                >
                  <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
    </div>

    <create-waybillrequest-modal ref="createWaybillrequestModal" />

  </div>
</template>

<script>
import {
  BButton,
  BBadge,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  VBTooltip,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store'

const waybillRequestRepository = RepositoryFactory.get('waybill')
const MerchantRepository = RepositoryFactory.get('merchant')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BFormGroup,
    BFormInput,
    BBadge,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    vSelect,
    BPagination,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      requested_proof_of_delivery_count: '',
      requested_waybill_count: '',
      filters: {},
      total: 0,
      meta: {},
      dataLoading: false,
      // Start BS Table
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100, 200],
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      dir: false,
      recordId: 0,
      fields: [
        {
          key: 'id',
          label: '#',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'request_date',
          label: 'Request Date',
          sortable: true,
        },
        {
          key: 'requested_proof_of_delivery_count',
          label: 'Proof of Delivery',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'requested_waybill_count',
          label: 'No Of Waybills',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'from_waybill',
          label: 'From Waybill No',
          sortable: true,
        },
        {
          key: 'to_waybill',
          label: ' To Waybill No',
          sortable: true,
        },
        {
          key: 'confirm_date',
          label: 'Confirm Date',
          sortable: true,
        },
        {
          key: 'issued_proof_of_delivery_count',
          label: 'Issued Proof of Delivery',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'issued_waybill_count',
          label: 'Issued Waybills',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'req_status',
          label: 'Status',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
      ],
      downloadReportName: 'Waybill-Request-Report',
      json_fields: {
        'Request Date': {
          field: 'request_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Proof of Delivery': {
          field: 'requested_proof_of_delivery_count',
          callback: value => `"${value}"`,
        },
        'No Of Waybills': {
          field: 'requested_waybill_count',
          callback: value => `"${value}"`,
        },
        'From Waybill No': {
          field: 'from_waybill',
          callback: value => `"${value}"`,
        },
        'To Waybill No': {
          field: 'to_waybill',
          callback: value => `"${value}"`,
        },
        'Confirm Date': {
          field: 'confirm_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Issued Proof of Delivery': {
          field: 'issued_proof_of_delivery_count',
          callback: value => `"${value}"`,
        },
        'Issued Waybills': {
          field: 'issued_waybill_count',
          callback: value => `"${value}"`,
        },
        Status: {
          field: 'req_status',
          callback: value => `"${value}"`,
        },
      },
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.fetchWaybillRequestList()
    },
    perPage() {
      this.fetchWaybillRequestList()
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchWaybillRequestList()
    this.getMerchantBusinessSettingsDetails()
  },
  methods: {
    async getMerchantBusinessSettingsDetails() {
      try {
        const data = localStorage.getItem('merchant_business_setting') ? JSON.parse(localStorage.getItem('merchant_business_setting')) : (await MerchantRepository.getMerchantBusinessSettingsDetails()).data.data
        if (!localStorage.getItem('merchant_business_setting')) localStorage.setItem('merchant_business_setting', JSON.stringify(data))
        this.merchant_id = data.merchant_id
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async sendWaybillRequestReport() {
      try {
        const payload = {
          requested_proof_of_delivery_count: this.requested_proof_of_delivery_count,
          requested_waybill_count: this.requested_waybill_count,
        }
        const res = await waybillRequestRepository.sendWaybillRequestReport(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Waybill Request Send')
          this.open = false
          this.onClickRefresh()
          this.requested_proof_of_delivery_count = ''
          this.requested_waybill_count = ''
        } else {
          this.showErorMessage(res.data.message)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchWaybillRequestList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await waybillRequestRepository.getWaybillRequestList(this.page, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.showErrorMessage(e)
      }
      this.dataLoading = false
    },
    async fetchWaybillRequestListNoPagination() {
      try {
        const { data } = (await waybillRequestRepository.getWaybillRequestListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.showErrorMessage(e)
        return []
      }
    },
    handleChangePage(page) {
      this.page = page
      this.fetchWaybillRequestList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.fetchWaybillRequestList()
    },
  },
}
</script>
<style scoped>
.custom-width {
  width: 120px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/required.scss';
@import '../../assets/scss/style.scss';
</style>
